<template>
  <div class="edit">
    <!-- title -->
    <Bar>
      <el-row
        :gutter="20"
        class="row"
      >
        <el-col :span="8">
          <span
            class="back"
            @click="$router.go(-1)"
          >
            <i class="el-icon-arrow-left" /> 返回
          </span>
        </el-col>
        <el-col
          :span="8"
          class="text-center title"
        >
          <template v-if="isAdd">
            新建测评用户
          </template>
          <template v-else>
            {{ disableEdit?'':'编辑：' }}{{ form.childrenName }}
          </template>
        </el-col>
        <el-col
          :span="8"
          class="text-right"
        >
          <el-button
            v-if="!disableEdit"
            round
            class="linear-btn save"
            :disabled="maxMonthAge"
            @click="submit"
          >
            <template v-if="isAdd">
              保存
            </template>
            <template v-else>
              保存修改
            </template>
          </el-button>
          <el-button
            v-else
            round
            class="linear-btn save"
            @click="close"
          >
            关闭
          </el-button>
        </el-col>
      </el-row>
    </Bar>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-position="top"
      class="form"
    >
      <!-- content 1 -->
      <div class="panel">
        <el-form-item
          label="监护人电话"
          prop="customerPhone"
        >
          <el-input
            v-model="form.customerPhone"
            type="text"
            :disabled="disableEdit"
            autocomplete="off"
            @blur="phoneAndNameChange"
          />
        </el-form-item>
        <el-form-item
          label="测评宝宝姓名"
          prop="childrenName"
        >
          <el-input
            v-model="form.childrenName"
            type="text"
            autocomplete="off"
            :disabled="disableEdit"
            @blur="phoneAndNameChange"
          />
        </el-form-item>
        <el-form-item
          label="测评宝宝性别"
          prop="childrenSex"
        >
          <el-select
            v-model="form.childrenSex"
            placeholder="请选择"
            :disabled="disableEdit"
          >
            <el-option
              v-for="item in userSexOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          prop="childrenBirthday"
          label="测评宝宝生日"
        >
          <p class="tips">
            注: 早产儿（≤37周）填写出生年月日应以预产期年月日为准，否则影响测评结论（生长追赶）
          </p>
          <el-date-picker
            v-model="form.childrenBirthday"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            :disabled="disableEdit"
          />
          <span class="assess-age">{{ assessAge?`(${assessAge})`:'' }}</span>
        </el-form-item>
        <el-form-item
          label="出生体重（kg）"
          prop="birthWeight"
        >
          <el-input
            v-model="form.birthWeight"
            v-inputValid="/^0|[^\d\.]$/g"
            type="number"
            autocomplete="off"
            :disabled="disableEdit"
            min="0"
          />
        </el-form-item>
      </div>
      <!-- content 2 -->
      <!-- <div class="panel">
        <el-form-item
          label="综合套餐时限（次）"
          prop="evalApplyNum"
        >
          <el-input
            v-model="form.evalApplyNum"
            v-inputValid="/^0|[^\d]/g"
            type="number"
            autocomplete="off"
            :disabled="disableEdit"
            min="0"
            placeholder="请输入套餐次数"
          />
        </el-form-item> -->
      <!-- <el-form-item prop="expertReport">
          <el-checkbox
            v-model="form.expertReport"
            :true-label="1"
            :false-label="0"
            :disabled="disableEdit"
          >
            申请专家报告
          </el-checkbox>
        </el-form-item> -->
      <!-- </div> -->
      <!-- content 3 -->
      <div class="panel">
        <el-form-item
          label="监护人关系"
          prop="guardianRelation"
        >
          <el-select
            v-model="form.guardianRelation"
            placeholder="请选择"
            :disabled="disableEdit"
          >
            <el-option
              v-for="item in guardianRelationOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="居住地"
          prop="curResideAddr"
        >
          <Area
            v-model="form.curResideAddr"
            :refresh.sync="refreshArea"
            :disabled="disableEdit"
          />
        </el-form-item>
        <el-form-item
          label="详细地址"
          prop="fullAddr"
        >
          <el-input
            v-model="form.fullAddr"
            autocomplete="off"
            :disabled="disableEdit"
          />
        </el-form-item>
        <el-form-item
          label="孕周（选填，申请了专家报告必填）"
          prop="gestationalWeeks"
        >
          <!-- <el-select
            v-model="form.gestationalWeeks"
            placeholder="请选择"
          >
            <el-option
              v-for="item in gestationalWeeksOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            />
          </el-select> -->
          <el-input
            v-model="form.gestationalWeeks"
            v-inputValid="/^0|[^\d]/g"
            type="number"
            min="0"
            placeholder="请输入孕周"
            :disabled="disableEdit"
          />
        </el-form-item>
        <el-form-item
          label="过敏疾病史记录（选填，申请了专家报告必填）"
          prop="beforeBirthRecord"
        >
          <el-input
            v-model="form.beforeBirthRecord"
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            :disabled="disableEdit"
          />
        </el-form-item>
        <el-form-item
          label="产时疾病史记录（选填，申请了专家报告必填）"
          prop="birthRecord"
        >
          <el-input
            v-model="form.birthRecord"
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            :disabled="disableEdit"
          />
        </el-form-item>
        <el-form-item
          label="家族疾病史记录（选填，申请了专家报告必填）"
          prop="familyDiseaseRecord"
        >
          <el-input
            v-model="form.familyDiseaseRecord"
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            :disabled="disableEdit"
          />
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getAge } from '@/utils/utils';
import { getUserInfo } from '@/utils/auth';
import {
  userSex,
  guardianRelation,
  // gestationalWeeks,
  // evalTimeLimit,
  createCustomer,
  updateCustomer,
  getCustomerInfo,
  getActiveCustomerInfo,
  getCustomerByPhoneAndName
} from '@/api/index';

export default {
  name: 'EditUser',
  components: {
    Bar: () => import('@/components/Layout/components/Bar.vue'),
    Area: () => import('@/components/Area/index.vue'),
  },
  data() {
    return {
      form: {
        customerPhone: '',
        childrenName: '',
        childrenSex: '',
        childrenBirthday: '',
        birthWeight: '',

        // evalApplyNum: '',
        expertReport: 0,

        guardianRelation: '',
        curResideAddr: '',
        fullAddr: '',
        gestationalWeeks: '',
        beforeBirthRecord: '',
        birthRecord: '',
        familyDiseaseRecord: ''
      },
      rules: {
        customerPhone: [{ required: true, message: '请输入监护人手机！', trigger: 'blur' }, {
          validator: (rule, value, callback) => {
            if (value === '') {
              callback(new Error('请输入手机号'));
            } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value.replace(/\s/g, ''))) {
              callback(new Error('请输入正确的手机号!'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }],
        childrenName: [{ required: true, message: '请输入宝宝姓名！', trigger: 'blur' }],
        childrenSex: [{ required: true, message: '请选择宝宝性别！', trigger: 'blur' }],
        childrenBirthday: [{ required: true, message: '请选择出生日期！', trigger: 'blur' }, {
          validator: (rule, value, callback) => {
            const overAge = this.overAge(value);
            if (value === '') {
              callback(new Error('请选择出生日期！'));
            } else if (overAge) {
              callback(new Error('宝宝已超龄，不适合本系统测评！'));
            } else {
              callback();
            }
          },
          trigger: 'change'
        }],
        birthWeight: [{ required: true, message: '请输入出生体重！', trigger: 'blur' }],
        gestationalWeeks: [{ required: true, message: '请输入孕周！', trigger: 'blur' }],
        // beforeBirthRecord: [{ required: true, message: '请输入产前疾病记录！', trigger: 'blur' }],
        // birthRecord: [{ required: true, message: '请输入出生时疾病记录！', trigger: 'blur' }],
        curResideAddr: [{ required: true, message: '请选择居住地址！', trigger: 'blur' }],
      },
      isSubmit: false,
      userSexOptions: [],
      guardianRelationOptions: [],
      // gestationalWeeksOptions: [],
      // evalTimeLimitOptions: [],
      refreshArea: false
    };
  },
  computed: {
    // 计算年龄
    assessAge() {
      if (!this.form.childrenBirthday) return '';
      return getAge(this.form.childrenBirthday);
    },
    isAdd() {
      return this.$route.name === 'addUser';
    },
    disableEdit() {
      return Boolean(this.$route.query.disableEdit);
    },
    customerOrgId() {
      const user = getUserInfo();
      if (user) {
        return user.customerOrgId || '';
      }
      return '';
    },
    maxMonthAge() {
      return this.overAge(this.form.childrenBirthday);
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const { id } = this.$route.params;
      if (id) {
        this.getActiveCustomerInfo(id);
      }
      this.userSex();
      this.guardianRelation();
      // this.gestationalWeeks();
      // this.evalTimeLimit();
    },
    overAge(date) {
      const month = getAge(date, { getMonth: true });
      const res = (month || 0) > 81.5;
      return res;
    },
    getActiveCustomerInfo(id) {
      const method = this.$route.query.isActivePage ? getActiveCustomerInfo : getCustomerInfo;
      method({ id }).then((res) => {
        const tmp = res.data || {};
        this.form = {
          ...tmp,
          expertReport: Number(tmp.expertReport)
        };
      });
    },
    submit() {
      this.isSubmit = true;
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.isSubmit = false;
          return;
        }
        this.updateUser();
      });
    },
    updateUser() {
      const methods = {
        1: createCustomer,
        0: updateCustomer
      };
      const params = {
        ...this.form,
        customerOrgId: this.customerOrgId
      };

      const msg = this.isAdd ? '保存' : '更新';
      methods[Number(this.isAdd)](params).then((res) => {
        this.$message.success(res.msg || (`${msg}成功！`));
        setTimeout(() => {
          this.$router.replace('/user/active');
        }, 1500);
      }, (err) => {
        this.$message.warning(err.msg || (`${msg}失败！`));
      });
    },
    // 性别
    userSex() {
      userSex().then((res) => {
        this.userSexOptions = res.data || [];
      });
    },
    // 监护人关系
    guardianRelation() {
      guardianRelation().then((res) => {
        this.guardianRelationOptions = res.data || [];
      });
    },
    // 孕周
    // gestationalWeeks() {
    //   gestationalWeeks().then((res) => {
    //     this.gestationalWeeksOptions = res.data || [];
    //   });
    // },
    // 套餐时限
    // evalTimeLimit() {
    //   evalTimeLimit().then((res) => {
    //     this.evalTimeLimitOptions = res.data || [];
    //   });
    // },
    phoneAndNameChange() {
      if (!this.form.customerPhone || !this.form.childrenName) return;

      // 请求放到timer中，避免重复调用
      let timer = null;
      this.$refs.form.validateField(['customerPhone', 'childrenName'], (errMessage) => {
        if (errMessage) return;
        clearTimeout(timer);
        timer = setTimeout(() => {
          this.getCustomerByPhoneAndName();
        }, 10);
      });
    },
    // 根据输入手机和宝宝姓名获取信息
    getCustomerByPhoneAndName() {
      const params = {
        customerPhone: this.form.customerPhone,
        childrenName: this.form.childrenName
      };
      getCustomerByPhoneAndName(params).then((res) => {
        this.refreshArea = true;
        this.form = { ...res.data || {}, ...params };
      });
    },
    close() {
      if (this.$route.query.isActivePage || this.isAdd) {
        this.$router.replace('/user/active');
      } else {
        this.$router.replace('/');
      }
    }
  },
};
</script>

<style lang="less" scoped>
.row{
  height: 40px;
  line-height: 40px;
}
.back{
  font-size: 16px;
  color: #000;
  cursor: pointer;
  padding: 10px;
}
.title{
  font-size: 18px;
  font-weight: 600;
  color: #000;
}
.save{
  padding: 0 40px;
}
.form{
  margin-top: 16px;
}
.panel{
  background: #FFF;
  border-radius: 4px;
  margin-top: 1px;
  padding-top: 16px;
  color: #333;
}
.assess-age{
  position: absolute;
  bottom: 0;
  left: 130px;
  height: 40px;
  line-height: 40px;
}
.tips{
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #999;
  margin: 0 0 10px;
}
/deep/ .el-form-item{
  margin: 0 auto;
  width: 500px;
  padding-bottom: 16px;
}
/deep/.el-select{
  display: block;
}
/deep/.el-date-editor.el-input,/deep/.el-cascader{
  width: 100%;
}
/deep/.el-form--label-top .el-form-item__label{
  line-height: 1;
}
/deep/.el-form-item__label,/deep/.el-input__inner{
  color:#333;
}
/deep/.el-form-item__error{
  top: -24px;
  left: unset;
  right: 0;
}
</style>
